
import { defineComponent, defineAsyncComponent } from 'vue'
import { PaginationInterface } from '@/shared/interfaces/pagination.interface'
import { DarkStoresActions } from '@/store/modules/darkstores/actions'
import { DarkStoreInterface } from '@/shared/interfaces/darkstore.interface'
import router from '@/router'

export default defineComponent({
  components: {
    SearchInput: defineAsyncComponent(() => import('@/shared/components/SearchInput.vue')),
    DynamicTable: defineAsyncComponent(() => import('@/shared/components/dynamic-table/DynamicTable.vue')),
    PageTitle: defineAsyncComponent(() => import('@/shared/components/PageTitle.vue')),
    BaseModal: defineAsyncComponent(() => import('@/shared/components/modals/BaseModal.vue')),
    DarkstoreSettings: defineAsyncComponent(() => import('@/views/account/darkstores/components/DarkstoreSettings.vue')),
    ZoneManagement: defineAsyncComponent(() => import('@/views/account/darkstores/components/ZoneManagement.vue'))
  },
  data () {
    return {
      current: 1,
      searchValue: '',
      tableFields: [
        { id: 'name', name: this.$t('table.name'), width: '18%', sortable: false, currentSort: false, sort: null },
        { id: 'city', name: this.$t('table.city'), width: '18%', sortable: false, currentSort: false, sort: null },
        { id: 'latitude', name: this.$t('table.latitude'), width: '18%', sortable: false, currentSort: false, sort: null },
        { id: 'longitude', name: this.$t('table.longitude'), width: '18%', sortable: false, currentSort: false, sort: null },
        { id: 'openTime', name: this.$t('table.openTime'), width: '18%', sortable: false, currentSort: false, sort: null },
        { id: 'closeTime', name: this.$t('table.closeTime'), width: '18%', sortable: false, currentSort: false, sort: null },
        {
          id: 'darkstoreSetting',
          type: 'component',
          name: this.$t('table.darkstoreSetting'),
          width: '18%',
          contentWidth: 'w-220',
          sortable: false,
          currentSort: false,
          sort: null,
          componentName: 'DynamicTableDarkstoreSetting',
          props: {
            settings: 'darkstoreSetting'
          },
          tooltipText: this.$t('table.darkstoreInfo')
        },
        {
          id: 'actions',
          type: 'component',
          name: '',
          width: '18%',
          contentWidth: 'w-18',
          sortable: false,
          currentSort: false,
          sort: null,
          componentName: 'DynamicTableDarkstoreActionsCell',
          props: {
            data: 'rowData' // rowData is all data of row
          },
          on: {
            editDarkstoreSettings: this.onEditDarkStoreSettings,
            zoneManagement: this.onManageDarkstoreZone
          }
        }
      ],
      selectedDarkstore: null as any,
      showDarkstoreSettingsPopup: false,
      showDarkstoreZoneManagementPopup: false
    }
  },
  methods: {
    onEditDarkStoreSettings (darkstore: DarkStoreInterface) {
      if (darkstore.darkstoreSetting) {
        this.selectedDarkstore = darkstore
        this.showDarkstoreSettingsPopup = true
      }
    },
    settingsUpdated () {
      this.selectedDarkstore = null
      this.showDarkstoreSettingsPopup = false
      this.init()
    },
    onManageDarkstoreZone (darkstore: DarkStoreInterface) {
      this.selectedDarkstore = darkstore
      this.showDarkstoreZoneManagementPopup = true
    },
    init (route = null) {
      const val = route || this.$route
      if (val.query.search) {
        this.searchValue = val.query.search as any
      }
      let params = JSON.parse(JSON.stringify(val.query))
      params = { pageSize: 10, pageNumber: val.query.pageNumber ? val.query.pageNumber : 1, ...params }
      this.$store.dispatch(`darkstores/${[DarkStoresActions.FETCH_DARKSTORES]}`, params)
    }
  },
  computed: {
    darkstores (): any[] {
      const ds = this.$store.getters['darkstores/getDarkStores']
      return ds.map((el: any) => {
        return {
          id: el.id,
          name: el.name,
          city: el.city.name,
          longitude: el.longitude,
          latitude: el.latitude,
          openTime: el.openTime,
          closeTime: el.closeTime,
          darkstoreSetting: el.darkstoreSetting
        }
      })
    },
    pagination (): PaginationInterface {
      return this.$store.getters['darkstores/getPagination']
    },
    loading (): boolean {
      return this.$store.getters['darkstores/getLoading']
    }
  },
  watch: {
    current (val) {
      router.push({ query: { ...this.$route.query, pageNumber: val } })
    },
    searchValue () {
      const queries = this.$route.query
      queries.pageNumber = '1'
      router.push({ query: { ...queries, search: this.searchValue } })
    },
    $route: {
      immediate: true,
      handler (val) {
        if (val.name === 'DarkStores') {
          this.init(val)
        }
      }
    }
  }
})
